import { createStore } from 'redux';

const initialState = {
  cart: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      return {
        ...state,
        cart: [...state.cart, action.item],
      };
    case 'REMOVE_FROM_CART':
      const itemIndex = state.cart.findIndex(item => item.id === action.item.id);
      if (itemIndex === -1) return state;

      return {
        ...state,
        cart: [
          ...state.cart.slice(0, itemIndex),
          ...state.cart.slice(itemIndex + 1),
        ],
      };
    default:
      return state;
  }
};
const store = createStore(reducer);

export default store;
